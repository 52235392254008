.BulletproofCTA--padding {
    padding:0 1px 0px 0;
}

.BulletproofCTA {
    margin-bottom:14px;
    border:2px solid #333333;
    background-color:#333333;
}

.BulletproofCTA--labelMainStyling {
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Suisse', Helvetica, Arial, sans-serif;
    color:#ffffff;
    padding: 23px 40px 23px 30px;
    margin: auto;
}

.BulletproofCTA--labelInnerStyling, .BulletproofCTA--iconInnerStyling {
    color:#ffffff;
    text-decoration:none;
}

.BulletproofCTA--arrowContainer {
    text-align: left;
    font-size: 14px;
    line-height:24px;
    font-family: Helvetica, Arial, sans-serif;
    color:#ffffff;
    padding: 23px 30px 23px 0px;
    margin: auto;
    font-weight: bold;
}