.Quote--contentWrapper {
    width:500px;
}

.Quote--copy {
    text-align: center;
    color:#333333;
    font-family: 'Optima', 'Humanist', Helvetica, Arial, sans-serif;
    font-size:24px;
    line-height:34px;
    word-break:keep-all;
    white-space:normal;
}

.Quote--author {
    text-align: center;
    color:#333333;
    font-family: 'Suisse', Helvetica, Arial, sans-serif;
    font-size:14px;
    line-height:28px;
    font-weight:bold;
}