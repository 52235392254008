.StandardTemplate--mainHeading {
    text-align: left;
    color:#333333;
    font-family: 'Suisse', Helvetica, Arial, sans-serif;
    font-size:24px;
    line-height:30px;
}

.StandardTemplate--fullWidthHeroImage {
    display:block;
}

.StandardTemplate--bodyCopyContainer {
    text-align: left;
    color:#333333;
    font-family: 'Suisse', Helvetica, Arial, sans-serif;
    font-size:16px;
    line-height:28px;
}

.StandardTemplate--secondaryBodyCopy{
    margin-top: 30px;
}

.StandardTemplate--fallbackLink {
    text-decoration: none;
    color:#666666;
}