.EmailTemplate--section--outerContainer {
    table-layout:fixed; max-width:700px;
}

.EmailTemplate--section--outerPadding {
    padding:0px 20px;
}

.EmailTemplate--section--innerContainer {
    width:620px;
}

.EmailTemplate--emptySpace {
    font-size:1px;
    line-height:1px;
}

.EmailTemplate--spacer {
    display:block;
}

.EmailTemplate--links {
    color:#333333; text-decoration:none; white-space: nowrap; word-break:keep-all;
}

.EmailTemplate--reviewContainer {
    color:#FFFEF3;
    font-family: Helvetica, Arial, sans-serif;
    font-size:1px;
}

.EmailTemplate--reviewContent {
    display: none;
    max-height: 0px;
    overflow: hidden;
}

.EmailTemplate--hackAfterReview {
    display: none;
    max-height: 0px;
    overflow: hidden;
}
