.Footer--topLinksContainer {
    text-align:center;
}

.Footer--topLinks {
    text-align: center;
    padding: 0 20px 0 20px;
    display: inline-block;
    color:#333333;
    font-family: 'Suisse', Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.Footer--bottomLinks {
    text-align: center;
    color:#666666;
    font-family:  'Suisse', Helvetica, Arial, sans-serif;
    font-size:12px;
    line-height:24px;
}

.Footer--contactLink {
    font-weight:600;
    text-decoration: none;
    color:#666666
}