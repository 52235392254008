@font-face {
    font-family: 'Optima';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/OPTIM__.TTF);
    }

    @font-face {
    font-family: 'Suisse';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/SuisseIntl-Regular.ttf);
    }

    @font-face {
    font-family: 'Humanist';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/Zap601Rm.ttf);
    }

    #root {
        height: 100vh;
        background-color: #EAEADE;
    }

    body {
    margin: 0 !important;
    padding: 0 !important;   
    -webkit-text-size-adjust: 100% !important;
    -ms-text-size-adjust: 100% !important;
    -webkit-font-smoothing: antialiased !important;
    }

    img {
    border: 0 !important;
    outline: none !important;
    }

    p {
    Margin: 0px;
    Padding: 0px !important;
    }

    table {
    border-collapse: collapse;
    mso-table-lspace: 0px;
    mso-table-rspace: 0px;
    }

    td,
    a,
    span {
    border-collapse: collapse;
    mso-line-height-rule: exactly;
    }

    .ExternalClass * {
    line-height: 100%;
    }

    .ao_defaultlink a {
    color: inherit !important;
    text-decoration: none !important;
    }

    span.MsoHyperlink {
    mso-style-priority: 99;
    color: inherit;
    }

    span.MsoHyperlinkFollowed {
    mso-style-priority: 99;
    color: inherit;
    }

    /*Stylesheet for devices with width up to 670px*/
    @media only screen and (max-width:670px) {
    .ao_td_wrapper {
    width: 100% !important;
    display: block;
    }

    .ao_wrapper {
    width: 100% !important;
    }

    .ao_wrapper_48 {
    width: 48% !important;
    }

    .ao_wrapper_90 {
    width: 90% !important;
    }

    .ao_wrapper_60 {
    width: 60% !important;
    }

    .ao_hide {
    display: none !important;
    }
    .ao_full_img {
    height: auto !important;
    }
    .ao_full_img img {
    width: 100% !important;
    height: auto !important;
    display: block;
    border: none;
    }

    .ao_partial_img img {
    width: 73% !important;
    height: auto !important;
    display: block;
    border: none;
    }

    .ao_partial_text {
    width: 75% !important;
    height: auto !important;
    display: block;
    border: none;
    }

    span[class=ao_divhide] {
    display: none !important;
    }
    }

    .footer a, [class="footer"] a {
        color:#666666 !important;
        text-decoration:none !important;
        font-weight:600 !important;
    }

    .footer p, [class="footer"] p {
        margin: 0;
    }